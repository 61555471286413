import React, { useEffect, useState, useMemo } from 'react';
import { IApiCallStatus, IFile } from '../../interfaces';
import {
    DEFAULT_PAGE_SIZE,
    showNotification,
    summarizeText
} from '../../utils';
import ScrollToTop from 'react-scroll-to-top';
import Table from '../../components/Table';
import useAppbar from '../../hooks/useAppbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomSearchBox from '../../components/CustomSearchBox';
import { DEBOUNCE_TIME } from '../../constants';
import CustomTooltip from '../../components/CustomTooltip';
import { getAllManualJournal } from '../../services/manualJournal.service';
import usePagination from "../../hooks/usePagination";
import { useTranslation } from 'react-i18next';
import Popup from '../../components/Popup';
import CustomHeader from '../../components/CustomHeader';
import { saveAs } from 'file-saver';

let currentPagination = 1;
let distinctUntilChange: any = null;

// interface IManualJournals {
//     id: string;
//     narration: string,
//     dateValue: string,
//     totalDebitValue: string,
//     totalCreditAmount: string,
//     [x: string]: any
// }

const ManualJournals = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [params, setParams] = useState({})
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});

    const [openFile, setOpenFile] = useState<any>();
    const [popupIsOpenForImage, setPopupIsOpenForImage] = useState<boolean>(false);

    const [manualJournals, page, onUpdateData, onUpdatePage] = usePagination({
        pageSize: DEFAULT_PAGE_SIZE
    })

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const cellRender = (key: string, row: any) => {
        return row[key] || 'N/A';
    };

    const viewImage = (file: IFile) => {
        setOpenFile(file)
        setPopupIsOpenForImage(true)
    }

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    };

    const columns = useMemo(
        () => [
            {
                Header: t("common:index"),
                accessor: (originalRow: any, index: any, c: any, d: any) => {
                    return <>{index + 1}</>;
                }
            },
            {
                Header: t("common:narration"),
                accessor: (originalRow: any) => {
                    const val = originalRow.narration;
                    const label = summarizeText(cellRender('narration', originalRow), 20);
                    return <CustomTooltip label={label}>{val || ''}</CustomTooltip>;
                }
            },
            {
                Header: t("common:date"),
                accessor: (originalRow: any) => {
                    return cellRender('dateValue', originalRow);
                }
            },
            {
                Header: t("common:debit"),
                accessor: (originalRow: any) => {
                    return '$' + cellRender(
                        'totalDebitAmount',
                        originalRow
                    );
                }
            },
            {
                Header: t("common:credit"),
                accessor: (originalRow: any) => {
                    return '$' + cellRender(
                        'totalCreditAmount',
                        originalRow
                    );
                }
            },
            {
                Header: t("common:supporting_docs"),
                accessor: (originalRow: any) => {
                    console.log('Original Row is: ', originalRow);
                    // const reconcileId = originalRow.reconcileId
                    const fileId = originalRow.files[originalRow.files.length - 1] && originalRow.files[originalRow.files.length - 1].fileId
                    const file = originalRow.files[originalRow.files.length - 1]
                    return <>
                        <div className='d-flex justify-content-center' >
                            <button className='btn p-0' disabled={!fileId || file?.fileUrl?.split(".").pop() === "zip"} onClick={() => viewImage(file)}>
                                <i className="bi bi-file-earmark-text" style={{ fontSize: '1.5rem', color: (fileId && !(file?.fileUrl?.split(".").pop() === "zip")) ? 'green' : 'grey' }} />
                            </button>
                            <button className='btn p-0' disabled={!fileId} onClick={() => downloadFile(file.fullFileUrl, file.originalName)}>
                                <i className="bi bi-download ms-3" style={{ fontSize: '1.5rem', color: fileId ? 'green' : 'grey' }} />
                            </button>
                        </div>
                    </>;
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const [querySearch, setQuery] = useState('');

    const fetchData = async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        search = querySearch,
        searchParams = params,
        cb?: (data: any) => void
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber,
                pageSize,
                search,
                ...searchParams
            };
            const bankTransResp = await getAllManualJournal(data);
            if (bankTransResp.status === 200) {
                const resData = bankTransResp.data;
                onUpdateData(resData)
                if (cb) {
                    cb(bankTransResp.data);
                }
                changeApiStatus(false, '');
            } else {
                throw new Error(bankTransResp.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        if (distinctUntilChange) {
            clearTimeout(distinctUntilChange);
            distinctUntilChange = null;
        }
        distinctUntilChange = setTimeout(() => {
            setQuery(value);
            currentPagination = 1;
            onUpdatePage({ ...page, current: currentPagination });
            fetchData(1, undefined, value.trim());
        }, DEBOUNCE_TIME);
    };

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if(currentParams) {
            setParams(currentParams)
        }
        let isMenu = true;
        if(currentParams) {
            if(currentParams.accountId || currentParams.startDate || currentParams.endDate) {
                isMenu = false;
            }
        }
        fetchData(undefined, undefined, undefined, currentParams);
        updateAppbar({
            leftLink: '',
            leftIcon: isMenu ? 'Menu' : 'Back',
            title: t("common:manual_journals"),
            showAddIcon: true,
            addLink: '/manual-journals/new',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const gotoDetailsPage = (id: string) => {
        navigate('/manual-journals/' + id);
    };

    useEffect(() => {
        // Check if the file is a PDF and open it in a new tab
        if (popupIsOpenForImage && openFile && openFile.fileUrl && openFile.fileUrl.split(".").pop() === "pdf") {
            const newTab = window.open(openFile.fullFileUrl, '_blank');
            if (newTab) newTab.opener = null; // Prevent the new tab from accessing the opener window
            setPopupIsOpenForImage(false);
        } else if(popupIsOpenForImage && openFile && openFile.fileUrl && openFile.fileUrl.split(".").pop() === "zip") {
            setPopupIsOpenForImage(false);
        }
    }, [popupIsOpenForImage, openFile]);

    return (
        <div className='transation-list'>
            <div className='row'>
                <div className='col-md-3'>
                    <CustomSearchBox key={t("common:search")} onChange={handleSearch} />
                </div>
            </div>
            {apiStatus.inProgress
? t("common:load")
: (
                <>
                    <div className='page-body'>
                        <Popup
                        isOpen={popupIsOpenForImage}
                        onCloseModal={() => setPopupIsOpenForImage(false)}
                        >
                        <CustomHeader title="" backFn={() => setPopupIsOpenForImage(false)} />
                        <div style={{
                            width: "100%",
                            height: "100%"
                        }}>
                            {openFile && openFile.fileUrl && (
                                <>
                                    {openFile.fileUrl.split(".").pop() === "pdf"
                                        ? <iframe src={openFile.fullFileUrl} style={{
                                            width: "100%",
                                            height: "100%"
                                        }} />
                                        : <>
                                        <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                        <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fullFileUrl, openFile.originalName) }}>
                                            {t("common:download_my_file")}
                                        </button>
                                        </div>
                                        <div style={{ width: "100%", height: "100%" }}>
                                        <img width="100%" height="auto" src={openFile.fullFileUrl} alt="text" className="mt-4 mb-3" />
                                        </div>
                                        </>}
                                </>)}

                        </div>
                        </Popup>

                        <ScrollToTop
                            smooth
                            className='ScrollToTop'
                            component={
                                <i className='bi bi-arrow-up-circle'></i>
                            }
                        />
                        <Table columns={columns} data={manualJournals} onRow={(id: string, data: string, colName: string) => {
                                if (colName !== t("common:supporting_docs")) {
                                gotoDetailsPage(id);
                                }
                        }} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ManualJournals;
