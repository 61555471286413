import React, { createRef, useEffect } from 'react'
import './styles/App.scss'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import SendOTP from './pages/sendOtp'
import PrivateLayout from './layouts/PrivateLayout'
import NotificationSystem from 'react-notification-system'
import {
  $dismissNotification,
  $editNotification,
  $showNotification,
  ENVIRONMENT
} from './utils'
import CategoryDetails from './pages/Category/CategoryDetails'
import Category from './pages/Category/Category'
import TransactionDetails from './pages/Transaction/TransactionDetails'
import UploadedTransactions from './pages/UploadedTransactions/UploadedTransactions'
import UploadedBankStatements from './pages/UploadedBankStatements/UploadedBankStatements'
import BankTransactions from './pages/BankTransactions/BankTransactions'
import Reconcile from './pages/BankTransactions/Reconcile'
import ManualJournals from './pages/ManualJournals/ManualJournals'
import ManualJournalDetails from './pages/ManualJournals/ManualJournalDetails'
import Landing from './pages/Landing'
import BalanceSheet from './pages/FinancialReports/BalanceSheet'
import FinancialReports from './pages/FinancialReports/FinancialReports'
import Contacts from "./pages/Contact/Contacts";
import ContactDetails from "./pages/Contact/ContactDetails";
import Settings from "./pages/Settings/Settings";
import Reconciliation from "./pages/Reconciliation/Reconciliation";
import Invoices from './pages/Invoices/Invoices'
import InvoiceComponent from './pages/Invoices/Invoice'
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from './constants/languages';
import UploadedFiles from './pages/CompanyDetails/CompanyUploadedItems'
import CompanyDetails from './pages/CompanyDetails/CompanyDetails'

const App = (): any => {
  const notificationSystem = createRef<any>()
  const { i18n, t } = useTranslation();

	const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const langCode = e.target.value;
		localStorage.setItem('selectedLanguage', langCode);
    window.location.reload();
		i18n.changeLanguage(langCode);
	};

  useEffect(() => {
    const notificationSystemRef = notificationSystem.current;

    const handleShowNotification = (val: any) => {
      if (notificationSystemRef) {
        notificationSystemRef.addNotification({
          ...val,
          position: 'bl',
          autoDismiss: val.uid !== undefined ? 0 : val.timeout,
          dismissible: val.uid !== undefined ? 'none' : 'both'
        });
      }
    };

    const handleDismissNotification = (val: any) => {
      if (notificationSystemRef) {
        notificationSystemRef.removeNotification(val);
      }
    };

    const handleEditNotification = (val: any) => {
      if (notificationSystemRef) {
        notificationSystemRef.editNotification(val.uid, { message: val.message });
      }
    };

    $showNotification.on('showNotification', handleShowNotification);
    $dismissNotification.on('dismissNotification', handleDismissNotification);
    $editNotification.on('editNotification', handleEditNotification);

    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }

    return () => {
      $showNotification.off('showNotification', handleShowNotification);
      $dismissNotification.off('dismissNotification', handleDismissNotification);
      $editNotification.off('editNotification', handleEditNotification);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, notificationSystem]);

  return <>
            <Routes>
                {/* public routes */}
                <Route path='/' element={<Landing />} />
                <Route path='/login' element={<Login />} />
				<Route path='/send' element={<SendOTP />} />
                <Route path='/*' element={<PrivateLayout />}>
                    <Route path='category' >
                        <Route index element={<Category />} />
                        <Route path=':id' element={<CategoryDetails />} />
                    </Route>
                    <Route path='transaction'>
                        <Route index element={<UploadedTransactions />} />
                        <Route path=':id' element={<TransactionDetails />} />
                    </Route>
                    <Route path='uploaded-bank-statements'>
                        <Route index element={<UploadedBankStatements />} />
                    </Route>
                    <Route path='invoices'>
                        <Route index element={<Invoices />} />
                        <Route path=':id' element={<InvoiceComponent />} />
                    </Route>
                    <Route path='bank-transactions'>
                        <Route index element={<BankTransactions />} />
                        <Route path='reconcile/:id' element={<Reconcile />} />
                    </Route>
                    <Route path='manual-journals'>
                        <Route index element={<ManualJournals />} />
                        <Route path=':id' element={<ManualJournalDetails />} />
                    </Route>
                    <Route path='reconciliation'>
                        <Route index element={<Reconciliation />} />
                    </Route>
                    <Route path='financial-reports'>
                        <Route index element={<FinancialReports />} />
                        <Route path='balance-sheet/:endDate' element={<BalanceSheet />} />
                    </Route>
                    <Route path='company-docs'>
                        <Route index element={<UploadedFiles />} />
                        <Route path=':id' element={<CompanyDetails />} />
                    </Route>
                    <Route path='contacts'>
                        <Route index element={<Contacts />} />
                        <Route path=':id' element={<ContactDetails />} />
                    </Route>
                    <Route path='settings'>
                        <Route index element={<Settings />} />
                    </Route>
                    <Route path='*' element={<Navigate to='/transaction' replace />} />
                </Route>
                <Route path='/' element={<Navigate to='/login' replace />} />
            </Routes>
            {ENVIRONMENT && <div className="environment">{t("common:dev_env")}</div>}
					<div className='multi-language'>
						<select value={i18n.language} onChange={onChangeLang}>
							{LANGUAGES.map(({ code, label }) => (
								<option key={code} value={code} >
									{label}
								</option>
							))}
            </select>
          </div>
            {/* {ENVIRONMENT !== undefined && <div className='environment print-hide'>{ENVIRONMENT}</div>} */}
            <NotificationSystem ref={notificationSystem} />
        </>
}

export default App
