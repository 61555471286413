import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import CustomHeader from '../../components/CustomHeader';
import InplaceConfirm from '../../components/InplaceConfirm';
import CustomHeader from '../../components/CustomHeader';
import Popup from '../../components/Popup';
// import Popup from '../../components/Popup';
import { paymentMethodOptions } from '../../constants';
import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import { IApiCallStatus, IFile } from '../../interfaces';
import {
    saveTransaction,
    getTransactionById,
    getAllCategory,
    uploadFile,
    deleteTransaction,
    getAllContact
} from '../../services';
import {
    dateFormatForInput,
    DEFAULT_PAGE_SIZE,
    dismissNotification,
    editNotification,
    groupArray,
    readFileAsDataUrl,
    showNotification
} from '../../utils';
import useAppbar from '../../hooks/useAppbar';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
const defaultValues = {
    categoryId: '',
    contactId: '',
    date: '',
    amount: '',
    paymentType: ''
};
const TransactionDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    // const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<{
        [x: string]: any;
    }>();

    const [openFile, setOpenFile] = useState<IFile>();
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [contacts, setContacts] = useState<Array<{ label: string, value: string; }>>([]);

    const params = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues
    } = useForm({
        mode: 'onBlur',
        defaultValues
    });

    const [categories, setCategories] = useState<any[]>([]);

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    // const [image, setImage] = useState<{ blob: any; url: string; }>({
    //     blob: null,
    //     url: '',
    // });

    const [images, setImages] = useState<IFile[]>([]);

    const onImageChange = async (e: any) => {
        try {
            // const [blob] = e.target.files;
            const files = [...e.target.files];
            const tempFiles = [];
            for (const file of files) {
                const valueOf = new Date().valueOf();
                const tempId = '' + valueOf;
                // console.log(tempId);
                const url = await readFileAsDataUrl(file);
                tempFiles.push({ tempId, blob: file, fileUrl: url, fileName: file.name, fileId: '' });
            }
            setImages([...images, ...tempFiles]);
            // if (blob) {
            //     // check the type
            //     if (blob.type.includes('image')) {
            //         // read the image file
            //         const url = await readFileAsDataUrl(blob);
            //         // setImage({ blob, url });
            //     } else {
            //         showNotification('error', 'Please choose a image file');
            //     }
            // }
        } catch (err: any) {
            console.log(err);
        }
    };

    const onSubmit = async () => {
        const values = getValues();
        changeApiStatus(true, '');
        const fileIds = [];
        if (images.length) {
            for (const img of images) {
                if (img.tempId) {
                    await upload(img.blob, (data: any) => {
                        fileIds.push(data._id);
                    });
                } else {
                    fileIds.push(img.fileId);
                }
            }
        }
        onSave({ ...values, fileIds });
    };

    const upload = (file: any, cb: (data: any) => void) => {
        const notificationUid = 'SaveFileUpload';
        try {
            showNotification('info', t("common:file_upload"), notificationUid);
            return uploadFile(file, (event: any) => {
                const uploadRes = event.data;
                if (event.event === 'laod') {
                    editNotification(
                        notificationUid,
                        `${t("common:uploading")} ${event.progressValue}%`
                    );
                } else if (event.event === 'complete') {
                    dismissNotification(notificationUid);
                    if (uploadRes.status === 200) {
                        cb(uploadRes.data);
                        return uploadRes.data;
                    } else {
                        throw new Error(uploadRes.error);
                    }
                }
            });
        } catch (err: any) {
            showNotification('error', err.message);
        }
    };

    const onDelete = async () => {
        const notificationUid = 'DeleteTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveTransactionRes = await deleteTransaction(transactionDetails?._id);
            dismissNotification(notificationUid);
            if (saveTransactionRes.status === 200) {
                showNotification('success', saveTransactionRes.message);
                navigate('/transaction');
            } else {
                throw new Error(saveTransactionRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const onSave = async (data: any) => {
        const notificationUid = 'SaveTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveTransactionRes = await saveTransaction({
                id: data._id || undefined,
                categoryId: data.categoryId,
                date: data.date,
                amount: data.amount,
                paymentType: data.paymentType,
                contactId: data.contactId || '',
                fileIds: data.fileIds || []
            });
            dismissNotification(notificationUid);
            if (saveTransactionRes.status === 200) {
                showNotification('success', saveTransactionRes.message);
                navigate(-1);
            } else {
                throw new Error(saveTransactionRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const fetchAllCategory = async (
        pageNumber = 1,
        query = '',
        pageSize = DEFAULT_PAGE_SIZE
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                query,
                pageNumber,
                pageSize,
                types: ['Income', 'Expense']
            };
            const usersResponse = await getAllCategory(data);
            if (usersResponse.status === 200) {
                const tempList = usersResponse.data.items.map((cat: any) => ({
                    label: cat.category,
                    value: cat._id,
                    typeLabel: cat.typeLabel
                }));
                const groupLis = groupArray(tempList, 'typeLabel', 'options', 'label');
                setCategories(groupLis);
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const getContacts = async () => {
        // const notificationUid = 'GetBank';
        try {
            changeApiStatus(true, '');
            // showNotification('info', 'Fetching...', notificationUid);
            const data = {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE
            };
            const getAllContactsRes = await getAllContact(data);
            // dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllContactsRes.status === 200) {
                const items = getAllContactsRes.data.items || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.contactName,
                        value: item._id
                    });
                    return item;
                });
                setContacts(tempList);
            } else {
                throw new Error(getAllContactsRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getTransactionDetails = async (id: any) => {
        const notificationUid = 'GetTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getTransactionByIdRes = await getTransactionById(id);
            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getTransactionByIdRes.status === 200) {
                const tempData = getTransactionByIdRes.data;
                setTransactionDetails(tempData);
                if (tempData.files) {
                    const tempFiles = tempData.files.map((item: any) => ({
                        blob: null,
                        fileUrl: item.fullFileUrl ? item.fullFileUrl : '',
                        fileName: item.originalName,
                        fileId: item.fileId
                    }));
                    setImages(tempFiles);
                }
                reset({
                    ...tempData,
                    date: dateFormatForInput(tempData.date)
                });
            } else {
                throw new Error(getTransactionByIdRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const viewImage = (file: IFile) => {
        setOpenFile(file);
        setPopupIsOpen(true);
    };

    const removeFile = (file: any) => {
        // console.log(file);
        const fileId = file.fileId || file.tempId;
        const tempFiles = images.filter((item: any) => {
            const itemId = item.fileId || item.tempId;
            console.log(fileId, itemId);
            if (itemId !== fileId) {
                return item;
            }
            return false;
        });

        // console.log('tempFiles', tempFiles);

        setImages(tempFiles);
    };

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    useEffect(() => {
        // console.log('params', params);
        fetchAllCategory();
        getContacts();
        if (params.id && params.id !== 'new') {
            getTransactionDetails(params.id);
        }
        updateAppbar({
            leftLink: '/transaction',
            leftIcon: 'Back',
            title: `${params.id !== 'new' ? t("common:edit") : t("common:new")} ${t("common:transaction")}`,
            showAddIcon: false,
            addLink: ''
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    };

    useEffect(() => {
        if (popupIsOpen && openFile && openFile?.fileName && (openFile?.fileName?.split(".").pop() === 'pdf')) {
            const pdfWindow = window.open("");
            console.log(openFile)
            pdfWindow?.document.write("<iframe width='100%' height='100%' src='" + openFile.fileUrl + "'></iframe>");
            setPopupIsOpen(false);
        }
    }, [popupIsOpen, openFile]);

    return (
        <div className='login-page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12 offset-md-4 text-center'>
                        {/* <CustomHeader title={`${transactionDetails?._id ? 'Edit' : 'Add New'} Transaction`} backFn={() => navigate('/transaction')} /> */}
                        <input
                            type='file'
                            accept='image/*'
                            name='camera'
                            id='opencamera'
                            onChange={onImageChange}
                            capture="environment"
                            hidden
                            disabled={images.length === 1}
                            // multiple
                        />
                        <input
                            type='file'
                            accept='*'
                            name='upload'
                            id='openupload'
                            onChange={onImageChange}
                            hidden
                            disabled={images.length === 1}
                            // multiple
                        />
                         <Popup
                        isOpen={popupIsOpen}
                        onCloseModal={() => setPopupIsOpen(false)}
                        >
                        <CustomHeader title="" backFn={() => setPopupIsOpen(false)} />
                        <div style={{ width: "100%", height: "100%" }}>
                            {openFile && openFile.fileUrl && (
                                <>
                                    <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                    <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fileUrl, openFile.fileName) }}>
                                        {t("common:download_my_file")}
                                    </button>
                                    </div>
                                    <div style={{ width: "100%", height: "100%" }}>
                                    <img width="100%" height="auto" src={openFile.fileUrl} alt="text" className="mt-4 mb-3" />
                                    </div>
                                </>
                            )}
                        </div>
                        </Popup>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className='mt-4'
                        >
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-flex justify-content-between'>
                                    {t("common:account")}
                                    <Link
                                        to='/category'
                                        className='text-decoration-none'
                                    >
                                        {t("common:manage")}
                                    </Link>
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:account")}
                                    required
                                    control={control}
                                    name='categoryId'
                                    disabled={false}
                                    selectClassNamePrefix='search-select'
                                    options={categories}
                                />
                            </div>

                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:date")}
                                </label>
                                <InputCtrl
                                    type='date'
                                    showError={showError}
                                    placeholder={t("common:date")}
                                    required
                                    control={control}
                                    name='date'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>

                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:amount_hkd")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:amount")}
                                    required
                                    control={control}
                                    name='amount'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>

                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:payment_type")}
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:payment_type")}
                                    required
                                    control={control}
                                    name='paymentType'
                                    disabled={false}
                                    // className='form-control'
                                    selectClassNamePrefix='search-select'
                                    options={paymentMethodOptions}
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-flex justify-content-between'>
                                {t("common:assign_contact")}
                                    <Link
                                        to='/contacts'
                                        className='text-decoration-none'
                                    >
                                        {t("common:manage")}
                                    </Link>
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:select_contact")}
                                    required={false}
                                    control={control}
                                    name='contactId'
                                    disabled={false}
                                    selectClassNamePrefix='search-select'
                                    options={contacts}
                                />
                            </div>
                            <div>
                                <label className='form-label text-start d-block'>
                                    {t("common:upload_file")}
                                </label>
                            </div>
                            <div className='col-12 input-group d-block' style={{
                                zIndex: 0
                            }}>
                                <div className='text-start mt-3'>
                                    <label
                                        className='w-25 btn btn-secondary login-btn mr-4 hand'
                                        style={{
                                            marginRight: 10
                                        }}
                                        htmlFor='opencamera'
                                    >
                                        {t("common:camera")}
                                    </label>
                                    <label
                                        className='w-25 btn btn-secondary login-btn cancel-btn hand'
                                        htmlFor='openupload'
                                    >
                                        {t("common:upload")}
                                    </label>
                                </div>
                            </div>
                            <div className='mt-3'>
                                {images?.map((file: any, idx: number) => {
                                    return <div className='mt-1' key={idx}>
                                        <div className='d-flex justify-content-between mb-4'>
                                        <div className='text-blue hand' style={{ pointerEvents: (file?.blob?.type?.split('/')[0] === 'image' || (file?.fileName?.split('.').pop() !== "zip")) ? "auto" : "none" }} onClick={() => viewImage(file)}>{file.fileName}</div>
                                            <div>
                                                <img
                                                    className='hand'
                                                    width={18}
                                                    height={18}
                                                    src='/icons/xmark.svg'
                                                    alt='img-icon'
                                                    onClick={() => removeFile(file)}
                                                />
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:save")}
                                </button>
                                <button
                                    type='reset'
                                    className='btn btn-secondary login-btn cancel-btn mt-3'
                                    disabled={apiStatus.inProgress}
                                    onClick={() => navigate('/transaction')}
                                >
                                    {t("common:cancel")}
                                </button>
                            </div>
                            {transactionDetails?._id && <div className='mt-4 mr-3'>
                                <InplaceConfirm
                                    HTMLComponent={<i className="bi bi-trash text-end" style={{ fontSize: '2rem', color: 'red' }}></i>}
                                    action={() => {
                                        console.log('onAction');
                                        onDelete();
                                    }}
                                    target="del-icon"
                                    text="Are you sure"
                                />
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;
