import React, { useEffect, useState, useMemo } from 'react';
import { IApiCallStatus, IPagination, IPieChartProps, IFile } from '../../interfaces';
import { getAllTransaction, getAllDateRange } from '../../services';
import { DEFAULT_PAGE_SIZE, dismissNotification, showNotification } from '../../utils';
import ScrollToTop from 'react-scroll-to-top';
import Table from '../../components/Table';
import LabelAndValue from '../../components/LabelAndValue';
import useAppbar from '../../hooks/useAppbar';
import { useDownloadFile } from '../../hooks/useDownloadFile';
// @ts-expect-error
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl, Label } from "react-bootstrap";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CustomHeader from '../../components/CustomHeader';
import Popup from '../../components/Popup';
import PieChart from '../../components/PieChart';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

let generateReportPdfUrl = "";
let startDate: any = "";
let endDate: any = "";
let dateRangeId = "";
const commonFormat = "YYYY-MM-DD";

const UploadedTransactions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isDownloading } = useDownloadFile({
        preDownloading: (notificationUid: string | undefined) => {
            showNotification('info', t("common:download_file"), notificationUid);
        },
        postDownloading: (notificationUid: any) => {
            dismissNotification(notificationUid);
            showNotification('success', t('common:downloaded'));
        },
        onError: (notificationUid: any, message: string) => {
            dismissNotification(notificationUid);
            showNotification('error', message);
        }
    });
    const [openFile, setOpenFile] = useState<any>();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [uploadedTransactions, setUploadedTransactions] = useState<any[]>([]);
    const [chartItems, setChartItems] = useState<any[]>([]);
    const [currentTranDetails, setCurrentTranDetails] = useState<any>();
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [popupIsOpenForImage, setPopupIsOpenForImage] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<any[]>([]);
    const [quickProfitAndLossList, setQuickProfitAndLossList] = useState<any[]>([]);
    const [ranges, setRanges] = useState<{ [x: string]: Array<any>; }>({});
    const [dateTimeRangeValue, setDateTimeRangeValue] = useState<string>("");
    const [amountChart, setAmountChart] = useState<IPieChartProps>({
        title: '', labels: [], values: [], backgroundColor: []
    });
    const [countChart, setCountChart] = useState<IPieChartProps>({
        title: '', labels: [], values: [], backgroundColor: []
    });
    const [chartSubTitle, setChartSubTitle] = useState<string>("");
    const [selectDates, setSelectDates] = useState<{ startDate: any, endDate: any; }>({
        startDate: moment(),
        endDate: moment()
    });
    const [hideCalendar, setHideCalendar] = useState<boolean>(false);

    const [page, setPage] = useState<IPagination>({
        totalItems: 0,
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 0,
        query: ''
    });

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const viewImage = (file: IFile) => {
        setOpenFile(file)
        setPopupIsOpenForImage(true)
    };

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    }
    const columns = useMemo(
        () => [
            {
                Header: t("common:tx_date"),
                accessor: 'dateValue' // accessor is the "key" in the data
            },
            {
                Header: t("common:tx_type"),
                // accessor: 'type',
                accessor: (originalRow: any) => {
                    return <><span onClick={() => gotoDetailsPage(originalRow._id)}>{originalRow.type}</span><i onClick={() => viewInvoice(originalRow)} className='bi bi-eye-fill text-primary hand' style={{ fontSize: "1.5rem", marginLeft: 5 }}></i></>;
                }
            },
            {
                Header: t("common:account"),
                accessor: 'category'
            },
            {
                Header: t("common:amount"),
                accessor: 'amountValue'
            },
            {
                Header: t("common:status"),
                accessor: (originalRow: any) => {
                    const reconcileId = originalRow.reconcileId
                    const fileId = originalRow.files[originalRow.files.length - 1] && originalRow.files[originalRow.files.length - 1].fileId
                    const file = originalRow.files[originalRow.files.length - 1]
                    return <>
                        <div className='d-flex justify-content-around' >
                            <button className='btn p-0' disabled={file?.fileUrl?.split(".").pop() === "zip" || !fileId} onClick={() => viewImage(file)}>
                                <i className="bi bi-file-earmark-text" style={{ fontSize: '1.5rem', color: (fileId && !(file?.fileUrl?.split(".").pop() === "zip")) ? 'green' : 'grey' }} />
                            </button>

                            {/* <button className='btn p-0' disabled={true} onClick={() => goto('/bank-transactions/reconcile/' + reconcileId)} >
                                <i className="bi bi-receipt" style={{ fontSize: '1.5rem', color: 'grey' }} />
                            </button> */}

                            <button className="btn p-0" disabled={!reconcileId} onClick={() => goto('/bank-transactions/reconcile/' + reconcileId)} >
                                <i className="bi bi-clipboard2-check" style={{ fontSize: '1.5rem', color: reconcileId ? 'green' : 'grey' }} />
                            </button>
                            <button className='btn p-0' disabled={!fileId} onClick={() => downloadFile(file.fullFileUrl, file.originalName)}>
                                <i className="bi bi-download" style={{ fontSize: '1.5rem', color: fileId ? 'green' : 'grey' }} />
                            </button>
                        </div>
                    </>;
                }
            }
        ],
        []
    );
    const chartListColumns = useMemo(
        () => [
            {
                Header: t("common:category_name"),
                accessor: 'category'
            },
            {
                Header: t("common:expense_count"),
                accessor: 'count'

            },
            {
                Header: t("common:amount"),
                accessor: 'amountValue'
            }
        ],
        []
    );
    const fetchData = async (
        pageNumber = page.current,
        query = page.query ?? '',
        pageSize = page.pageSize,
        downloadPdf = false,
        cb?: (data: any) => void
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                query,
                pageNumber,
                pageSize,
                dateRangeId: dateRangeId || undefined,
                startDate: !dateRangeId && startDate ? startDate : undefined,
                endDate: !dateRangeId && endDate ? endDate : undefined,
                downloadPdf
            };
            const usersResponse = await getAllTransaction(data);
            if (usersResponse.status === 200) {
                setPage({
                    ...page,
                    current: pageNumber,
                    totalItems: usersResponse.data.totalItems
                });
                const resData = usersResponse.data;
                if (resData.fullPdfUrl) {
                    generateReportPdfUrl = resData.fullPdfUrl;
                } else {
                    generateReportPdfUrl = "";
                }
                if (resData.quickProfitAndLossList) {
                    setQuickProfitAndLossList(usersResponse.data.quickProfitAndLossList);
                }
                if (resData.amountChart) {
                    setAmountChart(resData.amountChart);
                }
                if (resData.countChart) {
                    setCountChart(resData.countChart);
                }
                if (resData.chartItems) {
                    setChartItems(resData.chartItems);
                }
                if (resData.chartTitle) {
                    setChartSubTitle(resData.chartTitle);
                }
                if (cb) {
                    cb(usersResponse.data);
                }
                setUploadedTransactions(resData.items);
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const fetchDateRange = async () => {
        try {
            changeApiStatus(true, '');
            const dateRangeResponse = await getAllDateRange();
            if (dateRangeResponse.status === 200) {
                setDateRange(dateRangeResponse.data);
                const tempObj: { [x: string]: Array<any>; } = {};
                dateRangeResponse.data.map((ran: any, idx: number) => {
                    tempObj[`${ran.label} - ${ran.value}`] = [moment(ran.startDate).startOf('day'), moment(ran.endDate).endOf('day')];
                    return ran;
                });
                setRanges(tempObj);
                changeApiStatus(false, '');
            } else {
                throw new Error(dateRangeResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const generateReport = () => {
        if (!isDownloading) {
            const notificationUid = 'GetAllTransactions';
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            fetchData(1, '', page.pageSize, true, () => {
                dismissNotification(notificationUid);
                if (generateReportPdfUrl) {
                    window.open(generateReportPdfUrl, '_blank');
                } else {
                    showNotification('error', t("common:try_again"));
                }
            });
            setTimeout(() => {
                dismissNotification(notificationUid);
            }, 10000);
        }
    };

    useEffect(() => {
        startDate = "";
        endDate = "";
        fetchData();
        fetchDateRange();
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:transactions"),
            showAddIcon: false,
            addLink: '',
            rightComponent: <>
                <p className='text-info text-sm-start mt-3 hand' onClick={generateReport}> {t("common:report")}</p>
                <button type='button' onClick={() => goto('/transaction/new')} className='btn p-0'>
                    <i className="bi bi-plus-lg"></i>
                </button>
            </>
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const local = {
        format: "DD-MM-YYYY HH:mm",
        sundayFirst: false
    };

    const updateDateTimeRangeValue = (startDate: any, endDate: any) => {
        const tempStartdate = moment(startDate).format(commonFormat);
        const tempEndDate = moment(endDate).format(commonFormat);
        setDateTimeRangeValue(`${tempStartdate} - ${tempEndDate}`);
    };

    const applyCallback = (start: any, end: any) => {
        dateRangeId = "";
        setSelectDates({
            startDate: start,
            endDate: end
        });
        const tempStartDate = start.format(commonFormat);
        const tempEndDate = end.format(commonFormat);
        startDate = start.format(commonFormat);
        endDate = end.format(commonFormat);
        dateRange.map((ran) => {
            if (ran.startDate === tempStartDate && ran.endDate === tempEndDate) {
                dateRangeId = ran._id;
                startDate = "";
                endDate = "";
            }
            return ran;
        });
        fetchData(1);
        updateDateTimeRangeValue(start, end);
    };

    const rangeCallback = (index: any, value: any) => {
        if (value === 'Custom Range') {
            setHideCalendar(false);
        } else {
            setHideCalendar(true);
        }
    };

    const gotoDetailsPage = (id: string) => {
        navigate('/transaction/' + id);
    };

    const goto = (path: any) => {
        navigate(path);
    };

    const viewInvoice = (tran: any) => {
        setCurrentTranDetails(tran);
        setPopupIsOpen(true);
    };

    useEffect(() => {
        // Check if the file is a PDF and open it in a new tab
        if (popupIsOpenForImage && openFile && openFile.fileUrl && openFile.fileUrl.split(".").pop() === "pdf") {
            const newTab = window.open(openFile.fullFileUrl, '_blank');
            if (newTab) newTab.opener = null; // Prevent the new tab from accessing the opener window
            setPopupIsOpenForImage(false);
        } else if(popupIsOpenForImage && openFile && openFile.fileUrl && openFile.fileUrl.split(".").pop() === "zip") {
            setPopupIsOpenForImage(false);
        }
    }, [popupIsOpenForImage, openFile]);

    return (
        <div className='transation-list'>
            { apiStatus.inProgress
            ? t("common:load")
            : (<>
                    <div className='page-body'>
                        <Popup
                            isOpen={popupIsOpen}
                            onCloseModal={() => setPopupIsOpen(false)}
                        >
                            <CustomHeader
                                title='Invoice'
                                backFn={() => setPopupIsOpen(false)}
                            />
                            <div>
                                {currentTranDetails && currentTranDetails.files && currentTranDetails.files.map((file: any, idx: number) => {
                                    return <img
                                        key={idx}
                                        width="100%"
                                        height='auto'
                                        src={file.fullFileUrl}
                                        alt='text'
                                    />;
                                    })}
                                {(currentTranDetails && !currentTranDetails.files.length) && <img
                                    width="100%"
                                    height='calc(100vh - 250px)'
                                    src={'/images/no-file-found.png'}
                                    alt='text'
                                />}
                            </div>
                        </Popup>

                        <Popup
                        isOpen={popupIsOpenForImage}
                        onCloseModal={() => setPopupIsOpenForImage(false)}
                        >
                        <CustomHeader title="" backFn={() => setPopupIsOpenForImage(false)} />
                        <div style={{
                            width: "100%",
                            height: "100%"
                        }}>
                            {openFile && openFile.fileUrl && (
                                <>
                                    {openFile.fileUrl.split(".").pop() === "pdf"
                                        ? <iframe src={openFile.fullFileUrl} style={{
                                            width: "100%",
                                            height: "100%"
                                        }} />
                                        : <>
                                        <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                        <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fullFileUrl, openFile.originalName) }}>
                                            {t("common:download_my_file")}
                                        </button>
                                        </div>
                                        <div style={{ width: "100%", height: "100%" }}>
                                        <img width="100%" height="auto" src={openFile.fullFileUrl} alt="text" className="mt-4 mb-3" />
                                        </div>
                                        </>}
                                </>)}

                        </div>
                        </Popup>
                        <div className={`date-time-range-picker ${hideCalendar ? 'hidecalendar' : ''} `}>
                            <DateTimeRangeContainer
                                ranges={ranges}
                                start={selectDates.startDate}
                                end={selectDates.endDate}
                                local={local}
                                maxDate={undefined}
                                applyCallback={applyCallback}
                                rangeCallback={rangeCallback}
                                // autoApply
                                smartMode
                                pastSearchFriendly
                                // standalone
                                forceMobileMode
                                centerMode
                                style={{
                                    standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                                }}
                                className="ttt"
                            >
                                <Label>{t("common:date_range")}</Label>
                                <FormControl
                                    id="formControlsTextC"
                                    name='dateTimeRangeValue'
                                    type="text"
                                    label="Date range selector"
                                    placeholder={t("common:date_range_selector")}
                                    style={{ cursor: "pointer" }}
                                    disabled={false}
                                    // value={dateTimeRangeValue}
                                    defaultValue={dateTimeRangeValue}
                                />
                            </DateTimeRangeContainer>
                        </div>

                        <ScrollToTop
                            smooth
                            className='ScrollToTop'
                            component={
                                <i className='bi bi-arrow-up-circle'></i>
                            }
                        />
                        <div className='row mb-2 mt-2'>
                            {quickProfitAndLossList.map((item: any, idx: number) => {
                                return <div className='col' key={idx}>
                                        <LabelAndValue label={item.label} value={item.amountValue} />
                                </div>;
                            })}
                        </div>
                        {uploadedTransactions.length
? <>
                                <h3> {t("common:expense_breakdown")} </h3> <span>{chartSubTitle}</span>
                            </>
: ''}
                        {uploadedTransactions.length ? <PieChart {...amountChart} /> : ''}
                        {uploadedTransactions.length ? <PieChart {...countChart} /> : ''}
                        <Table columns={chartListColumns} data={chartItems} />
                        <br />
                        <br />
                        <Table columns={columns} data={uploadedTransactions} onRow={(id: string, data: string, colName: string) => {
                                if (colName !== t("common:transaction_type") && colName !== t("common:status")) {
                                gotoDetailsPage(id);
                                }
                        }} />
                    </div>
                </>
            )}
        </div>
    );
};

export default UploadedTransactions;
