import React, { useState } from 'react';

// import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { useTranslation } from 'react-i18next';

interface IInplaceConfirm {
    text: string;
    target: string;
    HTMLComponent: React.ReactNode;
    action: () => void;
}

const InplaceConfirm = ({ text, target, HTMLComponent, action }: IInplaceConfirm) => {
    const { t } = useTranslation();
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    const toggle = () => {
        setPopoverOpen(!popoverOpen);
    };
    const onAction = () => {
        toggle();
        action();
    }
    return (
        <>
            <div id={target || 'confirm-popover'} className='popover-confirm'>
                {HTMLComponent}
            </div>
            <Popover
                placement='auto'
                isOpen={popoverOpen}
                target={target || 'confirm-popover'}
                style={{ zIndex: 999999999 }}
                toggle={toggle}
            >
                <PopoverBody>
                    <PopoverHeader style={{ background: 'rgb(36, 58, 118)', color: '#ffffff' }}>{text}</PopoverHeader>
                    <PopoverBody>
                        <button
                            onClick={onAction}
                            className="btn btn-danger"
                            style={{
                                marginRight: 20
                            }}
                        >
                            {t("common:yes")}
                        </button>
                        <button className="btn btn-secondary" onClick={toggle}>{t("common:no")}</button>
                    </PopoverBody>
                </PopoverBody>
            </Popover>
        </>
    );
};

export default InplaceConfirm;
