// Here we have added our translations

export default {

  // Globals
  dev_env: 'Development', // Development Environment
  version: 'Version',
  logout: 'Logout',
  search: 'Search',
  all: 'All',
  coming_soon: 'Comming soon...',
  and: ' and ',
  year: 'Year',
  ai_analysing: 'AI Analysing',
  page: 'Page',
  download_pdf: 'Download Pdf',
  upload_new_docs: 'Upload New Documents',

  // Messages
  messages: {
    'Not Found': 'Not Found'
  },

  // Constants
  menu_transactions: 'P&L Transactions',
  chart_of_accounts: 'Chart Of Accounts',
  invoices: 'Invoices',
  uploaded_bank_statements: 'Uploaded Bank Statements',
  reconciliation: 'Reconciliation',

  // Form Fields
  field_require: 'Field is required',
  empty: 'Field can not be empty',
  select_option: 'Please select an option!',
  char_length: 'Must be 4 chars long', // Character Length
  select: 'select',
  no_clients: 'No clients available',
  max_length: 'Please enter a value of maxlength 30', // Maximum Length
  min_length: 'Please enter a value of minlength 3', // Minimum Length
  not_found: 'Not Found',
  invalid: 'Field is Invalid',
  max_char: 'Should be max 250 char long',
  min_char: 'Should be min 4 char long',
  invalid_email: 'Email address is invalid',
  are_you_sure: 'Are you sure',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  cancel: 'Cancel',
  submit: 'Submit',
  save: 'Save',

  // Table Data
  fetch: 'fetching...',
  load: 'Loading...',
  fetching: 'Fetching...',
  no_data: 'No Data',

  // Date Range Selection Constants
  from_date: 'From Date',
  to_date: 'To Date',
  select_from: 'Selecting From',
  select_to: 'Selecting To',
  max_date: 'Max Date',
  close: 'Close',
  apply: 'Apply',

  // Notifications
  file_upload: 'Starting file upload',
  start_uploads: 'Starting uploading your files...',
  upload_logo: 'Please upload logo',
  upload_process: 'Uploaded and processed',
  files: 'files',
  pdf_file: 'Please choose a pdf or image file',
  request_process: 'Processing your request',
  uploading: 'Uploading',
  company_documents: 'Company Documents',
  file_names: 'Filename',
  file_url: 'FileUrl',
  uploadfile: 'UploadFile',

  // Login
  welcome: 'Welcome',
  client_portal: 'AIFA Client Portal',
  email: 'Email',
  email_address: 'Email Address',
  password: 'Password',
  button: 'Remember Me',
  login: 'Login',
  unauthorized_access: 'Unauthorized access',

  // Uploaded Bank Statements
  not_found_coa: 'Not Found in COA',
  download_file: 'Downloading file',
  downloaded: 'Downloaded',
  transactions: 'Transactions',
  report: 'Generate Report',
  date_range: 'Date Range',
  date_range_selector: 'Date Range Selector',
  expense_breakdown: 'Expense Breakdown',
  category_name: 'Category Name',
  expense_count: 'Expense Count',
  amount: 'Amount',
  tx_date: 'Tx Date',
  tx_type: 'Transaction Type',
  account: 'Account',
  status: 'Status',

  // Invoices
  // chart_of_accounts: 'Chart of Accounts',
  invoice_no: 'Invoice No.',
  invoice_contact: 'Invoice Contact',
  invoice_date: 'Invoice Date',
  due_date: 'Due Date',
  total_amount: 'Total Amount',
  action: 'Action',
  // invoices: 'Invoices',
  invoice: 'Invoice',
  description: 'Description',
  quantity: 'Quantity',
  unit_price: 'Unit Price',
  subtotal: 'Subtotal',
  others: 'Others',
  notes: 'Notes',
  item_name: 'Item Name...',

  // Balance Sheet
  balance_sheet: 'Balance Sheet',
  no_transaction_found: 'No Transactions Found',

  // Uploaded Bank Statements Text
  multiple: 'Multiple',
  // uploaded_bank_statements: 'Uploaded Bank Statements',
  text_message: 'Please use COA to add a bank account. We support ',
  file_name: 'File Name',
  bank: 'Bank',
  added_on: 'Added on',
  open_coa: 'Open COA',
  statement_message: 'This statements contains entries for both saving and current account, Please select type of entries you should like',
  upload_message: 'Please select the file type to upload from following supported file types.',
  pdf_files: 'Pdf File',
  scanned_copy: 'Scanned Copy',
  upload: 'Upload',
  ai_message: 'These are AI generated results and may produce incorrect results. Please review the results, after your confirmation these transactions will be saved to our system.',
  no_accounts: 'No bank accounts found',
  no_file_type: 'No file type selected!',
  bank_name: 'Bank Name',
  multiple_accounts: 'Multiple bank accounts found',
  account_upload: 'The uploaded statements contain multiple accounts, Please select one(s) that match the account you are intended to upload for',
  not_supported: 'Support not available',
  file_support: 'Uploaded files support is not available.',
  something_went_wrong: 'Something Went Wrong',
  try_again: 'Something went wrong, please try again',
  no_data_found: 'No data found in this file, try with other file.',

  // Bank Transactions
  bank_transactions: 'Bank Transactions',
  index: 'Index',
  date: 'Date',
  summary: 'Summary',
  deposit: 'Deposit',
  withdrawl: 'Withdrawl',
  balance: 'Balance',
  // bank: 'Bank',
  reconcile: 'Reconcile',
  actions: 'Actions',
  view: 'view',
  amount_hkd: 'Amount (HKD)',
  payment_method: 'Payment Method',
  transaction_date: 'Transaction Date',
  description_: 'Description...',
  withdrawl_: 'Withdrawal ..',
  deposit_: 'Deposit ..',
  // balance: 'balance',
  account_: 'Account ...',
  date_: 'Date..',
  effective_date: 'Effective Date',

  // Reconcile
  reference: 'Reference',
  spent: 'Spent',
  recieved: 'Received',
  reconcile_amount: 'Bank transaction and manual transaction amount should match to move forward to reconcile',
  match: 'Match',
  discuss: 'Discuss',
  matching_transactions: 'Find & select matching transactions below',
  recieved_items: 'Show Received Items',
  show_hkd: 'Show HKD Items only',
  search_by_name: 'Search by name or reference',
  search_by_amount: 'Search by amount',
  go: 'Go',
  transaction_not_found: 'P&L Transactions Not Found',
  manage: 'Manage',
  select_all: 'Select all on this page',
  showing: 'Showaing',
  of: 'of',
  sum_of_transactions: 'The sum of your selected transactions must match the money',
  must_match: 'Must match money',
  undo_reconcile: 'Undo Reconcile',

  // Manual Journals
  manual_journals: 'Manual Journals',
  narration: 'Narration',
  debit: 'Debit',
  credit: 'Credit',
  default_narration: 'Default narration to journal line description',
  new_line: 'Add a new line',
  supporting_docs: 'Supporting Docs',
  download_my_file: 'Download File',

  // Reconcilation
  reconcilation: 'Reconcilation',
  reconciled: 'Reconciled',
  unreconciled: 'Unreconciled',

  // Financial Reports
  financial_reports: 'Financial Reports',
  income_statement: 'Income Statement',
  // balance_sheet: 'Balance Sheet',
  end_date: 'End Date',
  start_date: 'Start Date',
  all_reports: 'All reports',
  financial_statements: 'Financial statements',

  // New Contact Modal
  enter_contact_name: 'Enter Contact Name',
  primary_person: 'Primary Person',
  first_name: 'First Name',
  enter_first_name: 'Enter First Name',
  last_name: 'Last Name',
  enter_last_name: 'Enter Last Name',
  enter_email: 'Enter Email',
  business_info: 'Business Information',
  phone_no: 'Phone Number',
  enter_phone_no: 'Enter Phone Number',
  website_url: 'Website URL',
  enter_url: 'Enter URL',
  registration_no: 'Registration Number',
  enter_reg_no: 'Enter Reg. Number',
  select_bank: 'Select Bank',
  account_no: 'Account Number',
  enter_account_no: 'Enter Account Number',
  billing_address_line: 'Billing Address Line 1',
  address_line_two: 'Address Line 2',
  select_city: 'Select City',
  select_state: 'Select State',
  select_country: 'Select Country',
  enter_zip_code: 'Enter Zip Code',
  delivery_address: 'Delivery Address',
  address_line: 'Address Line 1',

  // Contacts
  contacts: 'Contacts',
  s_no: 'S. No.',
  contact_name: 'Contact Name',
  billing_address: 'Billing Address',

  // Contact Details
  edit: 'Edit',
  new: 'Add New',
  contact: 'Contact',
  none: 'none',

  // Transaction Details
  transaction: 'Transaction',
  payment_type: 'Payment Type',
  assign_contact: 'Assign Contact',
  select_contact: 'Select Contact',
  upload_file: 'Upload file',
  camera: 'Camera',
  gallery: 'Gallery',

  // Caegory Details
  type: 'Type',
  transaction_type: 'Transaction Type',
  bank_type: 'Bank Type',
  name: 'Name',

  // Invoice Details
  company_name: 'Company Name',
  req_company_name: 'Company Name is required',
  req_invoice_date: 'Invoice date is required',
  req_due_date: 'Due date is required',
  req_status: 'Status is required',
  req_contact: 'Contact is required',
  allow_decimal: 'Only decimal numbers are allowed',
  items_list: 'Items list can not be empty',
  items_description: 'Items description can not be empty',
  invalid_price: 'Found invalid price values in record',
  invalid_quantity: 'Found invalid quantity values in record',
  invalid_invoice: 'Found invalid invoice items',
  new_: 'New',
  draft: 'Draft',
  approved: 'Approved',
  sent: 'Sent',
  void: 'Void',
  add_new_item: 'Add new item',
  add_notes: 'Add your notes here ...',
  add_new_contact: 'Add New Contact',
  invoice_id: 'Invoice ID',
  qty: 'QTY',
  upload_gallery: 'Upload Gallery',
  upload_pdf: 'Upload PDF',

  // Manual Journal Details
  debit_and_credit: 'Debit and Credit amount should match',
  _new: 'New',
  manual_journal: 'Manual Journal',
  journal_description: 'Default narration to journal line description',
  // new_line: 'Add a new line',
  total: 'Total',
  send_otp: 'Send OTP',
  otp_mobile: 'OTP will be sent to your mobile number ends with',
  get_otp: 'Get Your OTP',
  verify_otp: 'Verify OTP',
  otp_mobile_verify: 'Enter OTP recieved on mobile number ends with',

  // Settings
  settings: 'Settings',
  financial_year_settings: 'Financial Year Settings',
  month: 'Month',
  select_month: 'Select Month',
  day: 'Day',
  select_day: 'Select Day',

  // Roles that we have used in our App
  roles: {
    FAKEROLE: 'FAKEROLE', // 0
    SUPERADMIN: 'SUPERADMIN', // 1 Super Admin
    LEGANCYADMIN: 'LEGANCYADMIN', // 2 User
    CPAADMIN: 'CPAADMIN', // 3, This is CPA Admin
    CPAAUDITOR: 'CPAAUDITOR', // 4, This is CPA User
    CPACLERK: 'CPACLERK', // 5 This is CPA User
    CLIENTADMIN: 'CLIENTADMIN', // 6 This is CPA Client
    CLIENTTEAMMEMBER: 'CLIENTTEAMMEMBER', // 7, old user
    BankAdmin: 'BankAdmin', // 8
    BankUSER: 'BankUSER', // 9
    BANKCLIENTADMIN: 'BANKCLIENTADMIN' // 10 This is Bank Client
  }

}
