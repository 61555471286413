import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import useAppbar from '../../hooks/useAppbar';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Popup from '../../components/Popup';
import { DEFAULT_PAGE_SIZE, dateFormatForInput, dismissNotification, showNotification } from '../../utils';
import CustomHeader from '../../components/CustomHeader';
import { getDefaultFinancialEndDate } from './../../utils/index';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from '../../hooks/useDownloadFile';
import { IApiCallStatus, IPagination } from '../../interfaces';
import { getAllTransaction } from '../../services';

let generateReportPdfUrl = "";
const dateRangeId = "";

const FinancialReports = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState<IPagination>({
        totalItems: 0,
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 0,
        query: ''
    });
    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };
    const { isDownloading } = useDownloadFile({
        preDownloading: (notificationUid: string | undefined) => {
            showNotification('info', t("common:download_file"), notificationUid);
        },
        postDownloading: (notificationUid: any) => {
            dismissNotification(notificationUid);
            showNotification('success', t('common:downloaded'));
        },
        onError: (notificationUid: any, message: string) => {
            dismissNotification(notificationUid);
            showNotification('error', message);
        }
    });
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [currRedirctPath, setCurrRedirctPath] = useState<string>('/financial-reports');
    const [popupMode, setPopupMode] = useState<string>("")
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dfaultFinancialEndDate] = useState(getDefaultFinancialEndDate())
    // const [selectedDate] = useState(getDefaultFinancialEndDate())
    const isLoaded = true;
    const [incomeStatement, setIncomeStatement] = useState({
        startDate: "",
        endDate: ""
    })
    useEffect(() => {
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:financial_reports"),
            showAddIcon: false,
            addLink: '',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goto = (path: string) => {
        navigate(path);
    };
    const list = [
        { subHeading: t("common:income_statement"), path: '/financial-reports' },
        { subHeading: t("common:balance_sheet"), path: '/financial-reports/balance-sheet' }
    ];

    const openNextPage = (date: string) => {
        setIsOpen(false)
        if(currRedirctPath !== '/financial-reports') {
            goto(currRedirctPath + '/' + date)
        }
    }

    const fetchData = async (
        pageNumber = page.current,
        query = page.query ?? '',
        pageSize = page.pageSize,
        downloadPdf = false,
        cb?: (data: any) => void
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                query,
                pageNumber,
                pageSize,
                dateRangeId: dateRangeId || undefined,
                startDate: !dateRangeId && incomeStatement.startDate ? incomeStatement.startDate : undefined,
                endDate: !dateRangeId && incomeStatement.endDate ? incomeStatement.endDate : undefined,
                downloadPdf
            };
            const usersResponse = await getAllTransaction(data);
            if (usersResponse.status === 200) {
                setPage({
                    ...page,
                    current: pageNumber,
                    totalItems: usersResponse.data.totalItems
                });
                console.log(usersResponse.data)
                const resData = usersResponse.data;
                if (resData.fullPdfUrl) {
                    generateReportPdfUrl = resData.fullPdfUrl;
                } else {
                    generateReportPdfUrl = "";
                }
                if (cb) {
                    cb(usersResponse.data);
                }
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };
    const generateReport = () => {
        if (!isDownloading) {
            const notificationUid = 'GetAllTransactions';
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            fetchData(1, '', page.pageSize, true, () => {
                dismissNotification(notificationUid);
                if (generateReportPdfUrl) {
                    window.open(generateReportPdfUrl, '_blank');
                } else {
                    showNotification('error', t("common:try_again"));
                }
            });
            setTimeout(() => {
                dismissNotification(notificationUid);
            }, 10000);
        }
    };

    const openDateTimeRangePicker = (data: any) => {
        setCurrRedirctPath(data.path);
        setPopupMode(data.subHeading)
        setIsOpen(true);
    };

    const onChangeDateForIncome = (name: string, val: any) => {
        setIncomeStatement({
               ...incomeStatement,
               [name]: val
        })
    }

    return (
        <div className='transation-list'>
            <div className='row'>
                <div className='col-md-3'>
                    {/* <CustomSearchBox key={'search'} onChange={handleSearch} /> */}
                </div>
            </div>
            {!isLoaded
? (
                t("common:load")
            )
: (
                <>
                    <div className='page-body'>
                        <ScrollToTop
                            smooth
                            className='ScrollToTop'
                            component={
                                <i className='bi bi-arrow-up-circle'></i>
                            }
                        />
                        <Popup isOpen={isOpen} onCloseModal={() => setIsOpen(false)} customStyles={{
                            content: {
                                textAlign: 'center'
                            }
                        }}>
                            <CustomHeader title='' rightItem={<div onClick={() => setIsOpen(false)}><img height={32} width={32} src='/icons/xmark.svg' alt='xmark' /></div>} />
                           {popupMode === t("common:balance_sheet")
? <div>
                           <h5>{t("common:end_date")}</h5>
                            <DatePicker
                                selected={new Date(dfaultFinancialEndDate)}
                                onChange={(date: Date) => null}
                                onSelect={(date: Date) => {
                                    const tempDate = dateFormatForInput(date.toJSON())
                                    openNextPage(tempDate)
                                }}
                                maxDate={new Date(dfaultFinancialEndDate)}
                                inline
                            />

                            </div>
: null}
                            {popupMode === t("common:income_statement")
? <div className='login-page'>
                           <h5 className="mt-3">{t("common:start_date")}</h5>
                                <DatePicker
                                placeholderText={t("common:start_date")}
                                // selected={new Date()}
                                value={incomeStatement.startDate}
                                onChange={(date: Date) => null}
                                onSelect={(date: Date) => {
                                    const tempDate = dateFormatForInput(date.toJSON())
                                    onChangeDateForIncome("startDate", tempDate);
                                }}
                                maxDate={new Date()}
                            />
                           <h5 className="mt-3">{t("common:end_date")}</h5>
                            <DatePicker
                                placeholderText={t("common:end_date")}
                                // selected={new Date()}
                                value={incomeStatement.endDate}
                                onChange={(date: Date) => null}
                                onSelect={(date: Date) => {
                                    const tempDate = dateFormatForInput(date.toJSON())
                                    onChangeDateForIncome("endDate", tempDate);
                                }}
                                maxDate={new Date()}
                            />
                             <button
                                    type='button'
                                    className='mt-3 btn btn-secondary login-btn'
                                    disabled={apiStatus.inProgress}
                                    // disabled={apiStatus.inProgress}
                                    onClick={() => {
                                        generateReport();
                                        setIsOpen(false)
                                    }}

                                >
                                    {t("common:submit")}
                                </button>
                            </div>
: null}
                        </Popup>

                        <div>
                        {t("common:all_reports")}
                            <hr />
                            <div>
                                <div>
                                    <h5>{t("common:financial_statements")}</h5>
                                </div>
                                <div className='border'>
                                    {list.map((item: any, idx: number) =>
                                        <div onClick={() => openDateTimeRangePicker(item)} key={idx} className={`${idx ? 'border-top' : ''} py-2 px-4 hand`}>
                                            <i className="bi bi-star me-2"></i> {item.subHeading}
                                        </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default FinancialReports;
